import React from 'react'
import './Footer.css'

export default () => (
    <footer className="footerHome">
        <span>
          © Copyright {new Date().getFullYear()} All rights reserved. Built by{' '}
          <a href="https://angryjenkins.com/">angryjenkins</a>.
        </span>
    </footer>
)
